import * as s from './styles/card_product.module.scss';
import React, { ReactElement } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { graphql, Link, useStaticQuery } from 'gatsby';

interface Props {
  title: string
  subtitle: string
  topics: string[]
}

const query = graphql`
  {
    allFile(filter: {relativeDirectory: {eq: "products"}}, sort: {fields: name}) {
      nodes {
        childImageSharp {
          gatsbyImageData
        }
        name
      }
    }
  }
`

export default function CardProduct({ title, subtitle, topics }: Props): ReactElement {
  const data = useStaticQuery(query)
  const images = data.allFile.nodes.filter((item: any) => item.name === title.toLowerCase())

  return (
    <Link to={`/produtos/${title.toLowerCase()}`} className={s.Card}>
      <div className={s.Card_box}>
        <GatsbyImage
          image={images[0].childImageSharp.gatsbyImageData}
          alt={title}
          className={s.Card_image}
          objectFit="contain" />
        <h1 className={s.Card_title}>{title}</h1>
        <h2 className="subtitle u-margin-bottom-small">{subtitle}</h2>
      </div>
      <div className={s.Card_listBox}>
        <ul className={`list ${s.Card_list}`}>
          {topics.map((value, i) => (
            <li key={`${title.toLowerCase()}${i}`} className={s.Card_item}>{value}</li>
          ))}
        </ul>
      </div>
    </Link>
  )
}

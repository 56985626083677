import * as s from '../styles/produtos.module.scss';
import CardProduct from '../../components/card_product';
import Layout from '../../components/layout';
import React, { ReactElement } from 'react';

export default function Produtos(): ReactElement {
  return (
    <Layout title="Produtos" gray>
      <main>
        <section className={s.Produtos}>
          <h1 className="title">Nossos produtos</h1>
          <h2 className="subtitle u-margin-bottom">Conheça os nossos sistemas de gestão</h2>
          <div className={s.Produtos_box}>
            <CardProduct
              title="MR1"
              subtitle="O Sistema de Gestão Empresarial da SysRS"
              topics={[
                'Se adapta a suas necessidades',
                'Módulos completos para seu segmento mercantil',
                'Outro tópico legal',
              ]}
            />
            <CardProduct
              title="MRE"
              subtitle="O Sistema de Esquadrias da SysRS"
              topics={[
                'Se adapta a suas necessidades',
                'Módulos completos para seu segmento mercantil',
                'Outro tópico legal',
              ]}
            />
            <CardProduct
              title="MRMobile"
              subtitle="O Sistema de Pedidos Mobile da SysRS"
              topics={[
                'Pedidos sem internet',
                'Catálogos personalizáveis',
                'Pedidos de pronta-entrega online',
              ]}
            />
          </div>
        </section>
      </main>
    </Layout>
  )
}
